<template>
  <router-link
    :to="card.link"
    class="shadow-lg w-full select-none cursor-pointer border-b-8"
    :class="`border-brand-${card.color}`"
  >
    <div class="h-64">
      <img :src="card.img" class="object-cover w-full h-full" alt="" />
    </div>
    <div class="px-8 py-5">
      <h2
        class="
          mt-10
          text-4xl
          uppercase
          text-brand-red
          hover:underline
          font-amatic-sc
        "
      >
        {{ card.title }}
      </h2>
      <p class="mt-5">{{ card.text }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["card"],
};
</script>

<style scoped></style>

<template>
  <div>
    <div class="flex">
      <h2 class="text-5xl uppercase text-brand-red font-amatic-sc">
        Das Programm
      </h2>
      <a
        :href="download"
        target="_blank"
        class="
          flex
          px-2
          ml-5
          space-x-2
          text-lg text-white
          bg-gray-300
          select-none
          cursor-pointer
        "
      >
        <img
          class="p-1 w-8 h-8 bg-gray-300 cursor-pointer self-center"
          src="@/assets/Icons/download.svg"
          alt=""
        />
        <div class="self-center uppercase">Programm</div>
      </a>
    </div>
    <div class="grid lg:grid-cols-2 gap-6 mt-10">
      <Days v-for="(day, index) in days" :key="index" :day="day" />
    </div>
  </div>
</template>

<script>
import Days from "./days.vue";
import { ref } from "vue";

export default {
  components: { Days },
  setup() {
    let download = ref("./../static/Programm-Kinderrechtekongress-2021.pdf");
    let days = [
      {
        title: "TAG 1",
        text: '<p class="mt-8">  <b>    14.00 - 14.10 Uhr  </b></p><p>  Begrüßung und Einführung in den ersten Veranstaltungstag durch die Moderatorin Miriam Zeleke (Beauftragte für Kinderrechte der hessischen Landesregierung)</p><p class="mt-8">  <b>    14.10 - 15.00 Uhr  </b></p><p>  Einführende Impulse aus drei Perspektiven zu den drei Begriffen des Titels "aufwachsen - gerecht - gestalten"</p><p>  a) die junge Perspektive Jonas Deitert und Kinder vom Redaktionsteam Kinderrechtereport der National Coalition Deutschland - Netzwerk zur Umsetzung der UN-Kinderrechtskonvention</p><p>  b) die politische Perspektive  Anne Janz Staatssekretärin für Soziales und Integration des Landes Hessen</p><p>  c) die wissenschaftliche Perspektive  Prof. Dr. Jutta Allmendinger Präsidentin des Wissenschaftszentrum Berlin GmbH</p><p class="mt-8">  <b>    10 Minuten Pause  </b></p><p class="mt-8">  <b>    15.10 - 16.40 Uhr  </b></p><p>  Moderierte Foren in denen die Erfahrungen und Perspektiven der Teilnehmer*innen gesammelt und diskutiert werden</p><p class="mt-8">  <b>    16.40 - 16.55 Uhr  </b></p><p>Phantasie und Ausdruck - Theater Direkt Performance von 4-6 Jährigen (Kalliope Noll, Theaterpädagogin und Schauspielerin)</p><p class="mt-8">  <b>    16.55 - 17.00 Uhr  </b></p><p>  Abschließende Worte und Einladung für den nächsten Tag durch die Moderatorin Miriam Zeleke</p>',
      },
      {
        title: "TAG 2",
        text: '<p class="mt-8">  <b>    09.00 - 09.05 Uhr  </b></p><p>  Begrüßung und Einführung in den zweiten Kongresstag durch Miriam Zeleke</p><p class="mt-8">  <b>    09.05 - 09.20 Uhr  </b></p><p>  Einstieg in den Tag mit Teresa Bücker </p><p class="mt-8">  <b>    09.20 - 09.55 Uhr  </b></p><p>  Kurzvorträge zum Thema "Aufwachsen im Sinne von Kinderrechten gestalten" mit Dr. Katharina Gerarts, Karl Kübel Stiftung I Kinderrechte Institut I OUTLAW.die Stiftung und Claudia Kittel, Deutsches Institut für Menschenrechte I UN-Kinderrechtskonvention Monitoringstelle</p><p class="mt-8">  <b>    5 Minuten Pause  </b></p><p class="mt-8">  <b>    10.00 - 11.25 Uhr  </b></p><p>  Workshops Parallelangebot von 16 Workshops zum Thema "Kinderrechte gestalten"</p><p class="mt-8">  <b>    5 Minuten Pause  </b></p><p class="mt-8">  <b>    11.30 - 12.30 Uhr  </b></p><p>  Podiumsdiskussion "Gerechtes Aufwachsen gestalten und ausbauen - Herausforderungen für Politik, Gesellschaft und Wissenschaft" Moderation durch Dr. Katharina Gerarts mit Prof. Dr. Tanja Betz, Johannes Gutenberg-Universität Mainz <br>  mit Dr. Heide-Rose Brückner, Kinderfreundliche Kommunen e.V. von UNICEF und den Deutschen Kinderhilfswerk <br>  mit Bianka Pergande, Geschäftsführerin Deutsche Liga für das Kind und Sprecherin der National Coalition Deutschland  <br>  mit Prof. Dr. Christian Schrapper, 1. Vorsitzender OUTLAW.die Stiftung <br>  mit Finn Siebold, Vorstandsmitglied im Bildungswerk für Schülervertretung und Schülerbeteiligung e.V. <br></p><p class="mt-8">  <b>    12.30 - 12.40 Uhr  </b></p><p>  Verabschiedung durch die Moderatorin Miriam Zeleke</p>',
      },
    ];
    return {
      download,
      days,
    };
  },
};
</script>

<style scoped></style>

<template>
  <div class="px-5 py-20 mx-auto max-w-screen-xl lg:px-0">
    <Intro />
    <div class="grid gap-6 mt-20 md:grid-cols-2 lg:grid-cols-3">
      <Card v-for="(card, index) in cards" :key="index" :card="card" />
    </div>
    <Schedule class="mt-20" />
  </div>
</template>

<script>
import Intro from "../components/home/introduction.vue";
import Card from "../components/home/card.vue";
import Schedule from "../components/home/schedule.vue";

import firebase from "firebase";
import { ref } from "vue";

export default {
  components: { Intro, Card, Schedule },
  setup() {
    let cards = ref([
      {
        link: "/livestream",
        src: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
        img: require("../assets/Bilder/firmbee-com-SpVHcbuKi6E-unsplash.jpeg"),
        title: "Hier geht es zum Livestream",
        text: "Verfolgen Sie hier alle Vorträge, Diskussionen und Wortbeiträge am ersten und zweiten Kongresstag. Zu diesem Bereich haben Sie freien Zugang und können sich ganz nach Belieben zuschalten.",
        color: "red",
      },
      {
        link: "",
        src: "",
        img: require("../assets/Bilder/chris-montgomery-smgTvepind4-unsplash.jpeg"),
        title: "Hier geht es zu Ihrem Forum",
        text: "Am ersten Kongresstag, den 16.09.2021 finden hier unsere moderierten Foren um 15.10 Uhr statt. Zu diesem Bereich wählen Sie sich mit dem für Sie vergebenen password ein. Viel Spaß beim gemeinsamen Austausch von Erfahrungen und Perspektiven.",
        color: "green",
      },
      {
        link: "",
        src: "",
        img: require("../assets/Bilder/leon-Oalh2MojUuk-unsplash.jpeg"),
        title: "Hier geht es zu Ihrem Workshop",
        text: "Am zweiten Kongresstag, den 17.09.2021 finden hier unsere Workshops um 10:00 Uhr statt. Sie haben sich für einen Workshop direkt in Ihrem Interessengebiet angemeldet. Zu diesem Bereich wählen Sie sich mit dem für Sie vergebenen password ein und erhalten exklusiven Zugang. Viel Spaß bei themenspezifischen Diskussionen sowie Erfahrungs- und Informationsaustausch.",
        color: "blue",
      },
    ]);

    const id = firebase.auth().currentUser.uid;

    let user = firebase.firestore().collection("users").doc(id);

    user.get().then((doc) => {
      if (doc.exists && doc.data().workshop !== "") {
        cards.value[1].link = doc.data().forum;
        cards.value[2].link = doc.data().workshop;
      } else if (doc.data()?.workshop === "") {
        cards.value[1].link = doc.data().forum;
        cards.value[2].link = "/error";
      } else {
        let admin = firebase.firestore().collection("admins").doc(id);
        admin.get().then((doc) => {
          if (doc.exists) {
            cards.value[1].link = "/admin";
            cards.value[2].link = "/admin";
          }
        });
      }
    });

    return {
      cards,
    };
  },
};
</script>

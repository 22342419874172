<template>
  <div class="shadow-lg w-full">
    <div class="relative mb-28">
      <div class="absolute inset-0 h-80 grid">
        <img
          class="object-cover w-full h-full filter blur-sm"
          src="@/assets/Bilder/AdobeStock_288918723.jpeg"
          alt=""
        />
        <div
          class="absolute inset-0 bg-brand-blue mix-blend-multiply"
          aria-hidden="true"
        ></div>
        <p
          class="absolute place-self-center text-white text-7xl font-amatic-sc"
        >
          {{ day.title }}
        </p>
      </div>
    </div>
    <div class="px-8 py-5">
      <p class="mt-56" v-html="day.text"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["day"],
};
</script>

<style scoped></style>
